import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select.js";
import { mobile } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput.js";

import { IS_APP } from "@/__main__/constants.mjs";
import type { PassiveSkill } from "@/game-palworld/models/model-wiki.mjs";
import type { PassiveCategory } from "@/game-palworld/utils/passives-utils.mjs";
import {
  categoryOptions,
  categoryToEffectMap,
  TierChevron,
} from "@/game-palworld/utils/passives-utils.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SortOrder from "@/shared/SortOrder";
import { useQuery } from "@/util/router-hooks.mjs";

const Styled = {
  pageHeader: () => css`
    & {
      padding-block: var(--sp-1) var(--sp-5);
      border-bottom: 1px solid var(--shade6-50);
    }
  `,
  dataTable: () => css`
    .tier {
      color: var(--c);

      &.neg {
        transform: rotate(180deg);
      }
    }
  `,
  searchContainer: () => css`
    display: flex;
    flex-direction: row;
    gap: var(--sp-2);

    ${mobile} {
      flex-direction: column;

      .selectButton {
        width: 100%;
      }
    }
  `,
};

type Sort = (typeof sortOptions)[number]["value"];
const sortOptions = [
  {
    value: "name",
    text: ["palworld:sort.name", "Name"] as Translation,
  },
  {
    value: "tier",
    text: ["palworld:sort.tier", "Tier"] as Translation,
  },
] as const;

const PassiveList = () => {
  const { t } = useTranslation();
  const { passives: passiveMap } = useWikiData();

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [categoryFilter, setCategoryFilter] = useQuery<PassiveCategory | "">(
    "category",
    "",
  );
  const [sortMode, setSortMode] = useQuery<Sort>("sort", "name");
  const [sortOrder, setSortOrder] = useQuery<"ASC" | "DESC">("order", "ASC");

  const passives = useMemo(() => {
    if (!passiveMap) return null;
    let passives = Object.values(passiveMap);
    if (categoryFilter) {
      passives = passives.filter(({ effects }) =>
        effects.some(({ type }) =>
          categoryToEffectMap[categoryFilter].some((effect) => effect === type),
        ),
      );
    }
    if (nameFilter) {
      passives = passives.filter(({ label }) =>
        t(...label)
          .toLocaleLowerCase()
          .includes(nameFilter),
      );
    }
    const sortVal =
      sortMode === "name"
        ? (a: PassiveSkill, b: PassiveSkill) =>
            t(...a.label).localeCompare(t(...b.label))
        : (a: PassiveSkill, b: PassiveSkill) => b.tier - a.tier;

    const sortFunc = (a: PassiveSkill, b: PassiveSkill) =>
      sortOrder === "ASC" ? sortVal(a, b) : sortVal(b, a);
    passives.sort(sortFunc);
    return passives;
  }, [passiveMap, categoryFilter, nameFilter, sortMode, t, sortOrder]);

  return (
    <Container className="flex column gap-sp-4">
      <PageHeader
        title={t("palworld:database.passiveSkills.title", "Passive Skills")}
        className={Styled.pageHeader()}
      />
      <div className={Styled.searchContainer()}>
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          value={nameFilter}
          Icon={SearchIcon}
        />
        <Select
          options={categoryOptions}
          selected={categoryFilter || ""}
          onChange={(v) => setCategoryFilter(v || undefined)}
        />
        <div className="flex flex-grow justify-end gap-sp-2">
          <Select
            options={sortOptions}
            selected={sortMode || ""}
            onChange={(v) => setSortMode(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <DataTable
        className={Styled.dataTable()}
        sortable={false}
        cols={[
          {
            display: t("palworld:tier", "Tier"),
          },
          {
            display: t("palworld:name", "Name"),
            align: "left",
            primary: true,
          },
          {
            display: t("palworld:effects", "Effect(s)"),
            align: "left",
          },
        ]}
        rows={passives?.map(({ label, tier, description }) => [
          {
            value: null,
            display: (
              <div>
                <span className="visually-hidden">{tier}</span>
                <TierChevron tier={tier} />
              </div>
            ),
          },
          {
            value: null,
            display: t(...label),
          },
          {
            value: null,
            display: t(...description),
          },
        ])}
      />
    </Container>
  );
};

export function meta() {
  if (IS_APP)
    return {
      title: ["palworld:database.passiveSkills.title", "Passive Skills"],
      description: [],
    };

  return {
    title: ["palworld:database.passiveSkills.title", "Passive Skills"],
    description: [
      "palworld:passiveSkillsLanding.desc",
      "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld passive skills, their effects, and their rank.",
    ],
    subtitle: true,
  };
}

export default PassiveList;
